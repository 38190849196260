p.info {
  text-indent: 15px;
}
.container-spacing {
  margin-top: 50px;
}
h3 {
  margin-bottom: 20px;
}

footer {
  background: #292b2c;
  color: white;
  padding: 20px;
  margin-top: 50px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0px;
}

div.footer {
  color: #7D7472;
  font-weight: bold;
}

.footer-link {
  text-decoration: none;
  color: white;
}
.footer-link:hover {
  color: white;
}
.row-footer {
  text-align: left;
}

.schedule {
  color: #292b2c;
  font-size: 30px;
  background-color: #86a367;
  border: none;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}