.main-container {
  margin-top: 100px;
}

/*First event has no border */
.first-event {
  padding-top: 15px;
  width: 98%;
  margin: auto;
}
/*All other events have a border*/
div.events {
  border-top: 1px solid #E3E2E2;
  width: 98%;
  margin: auto;
  padding-top: 15px;
}

.myCal {
  margin: auto;
}

.events-wrapper {
  margin: auto;
  border: 1px solid grey;
  width: 98%;
  border-radius: 3px;
  background-color: #86A367;
   box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}
.header-container {
  text-align: center;
}

.section-header {
  width: 98%;
  margin: auto;
  margin-bottom: 10px;
}

#manage-header {
  padding-top: 15px;
  border-bottom: 1px solid #E3E2E2;
  padding-bottom: 10px;
}

.event-header {
  width: 98%;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

button.manage-view {
  border:none;
   background-color:transparent;
   outline:none;
   font-size: 18px;
   margin-left: 20px;
   margin-bottom: 10px;
   text-decoration: underline;
   color: #86A367;
}